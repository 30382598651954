import { type SubmissionResult, getFormProps, getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import type { ActionFunctionArgs } from '@remix-run/node';
import { json, useFetcher } from '@remix-run/react';
import { z } from 'zod';
import { ErrorList } from '~/components/form/forms.tsx';
import { Input } from '~/components/ui/input.tsx';
import { StatusButton } from '~/components/ui/status-button.tsx';
import { tryParseFormData } from '~/utils/form-data.server.ts';
import { cn } from '~/utils/misc.ts';

const emailSchema = z.object({
  email: z
    .string({ required_error: 'Please enter an email address' })
    .email({ message: 'Please enter a valid email address' }),
});

export function SubscribeForm() {
  const fetcher = useFetcher<typeof action>({ key: 'subscribe-form' });

  const [form, fields] = useForm({
    id: 'newsletter-form',
    constraint: getZodConstraint(emailSchema),
    lastResult:
      fetcher.data && 'submission' in fetcher.data
        ? (fetcher.data.submission as SubmissionResult<string[]>)
        : undefined,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailSchema });
    },
    shouldRevalidate: 'onInput',
  });

  const isSuccess = fetcher.data && 'ok' in fetcher.data && fetcher.data.ok;

  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center border-gray-200 border-t px-4 pt-12',
        isSuccess && 'pb-4',
      )}
    >
      <div className="flex flex-col gap-3 sm:w-[480px]">
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-2xl">
            {isSuccess ? 'Thanks for signing up!' : 'Join the hostU crew'}
          </p>
          <p className="text-gray-600">
            {isSuccess
              ? 'Check your email to confirm your subscription.'
              : 'Get access to exclusive listings, tips for smooth renting and more.'}
          </p>
        </div>
        {!isSuccess && (
          <fetcher.Form
            {...getFormProps(form)}
            method="POST"
            action="/resources/newsletter-subscribe"
            className="flex gap-2"
          >
            <div className="w-full">
              <Input
                {...getInputProps(fields.email, { type: 'email' })}
                name="email"
                placeholder="Your .edu email"
                autoComplete="email"
                className="text-base"
              />
              <p className="flex h-6 items-center pl-2">
                <ErrorList errors={fields.email.errors} />
              </p>
            </div>
            <StatusButton
              type="submit"
              className="text-lg"
              status={fetcher.state === 'idle' ? 'idle' : 'pending'}
              disabled={fetcher.state !== 'idle'}
            >
              Subscribe
            </StatusButton>
          </fetcher.Form>
        )}
      </div>
    </div>
  );
}

export async function action({ request, context }: ActionFunctionArgs) {
  const parseResult = await tryParseFormData(request);

  if (!parseResult.success) {
    context.sentry.captureMessage('Form data parsing failed', {
      level: 'error',
      extra: {
        url: request.url,
        method: request.method,
        headers: Object.fromEntries(request.headers.entries()),
        error: parseResult.error,
        text: parseResult.text,
      },
    });

    return json({ error: parseResult.error, text: parseResult.text }, { status: 400 });
  }

  const formData = parseResult.data;

  const submission = parseWithZod(formData, {
    schema: emailSchema,
  });

  if (submission.status !== 'success') {
    return json({ submissiom: submission.reply() }, { status: 400 });
  }

  await context.klaviyo.subscribeToNewsletter(submission.value.email);

  return { ok: true };
}
